import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/Layouts/Base"
import Sidebar from "../../components/Integrations/Sidebar"

import headerImg from "../../images/integrations/qwilr/header.png"
import img1 from "../../images/integrations/qwilr/1.png"
import img2 from "../../images/integrations/qwilr/2.png"
import img3 from "../../images/integrations/qwilr/3.png"
import img4 from "../../images/integrations/qwilr/4.png"
import img5 from "../../images/integrations/qwilr/5.png"
import img6 from "../../images/integrations/qwilr/6.png"
import img7 from "../../images/integrations/qwilr/7.png"

export const query = graphql`
  query QwilrIntegrationQuery {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: 0
      limit: 100
      filter: { showInSidebar: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
  }
`

const IntegrationGlideAppsPage = ({ data }) => {
  const posts = data.allSanityPost.edges

  return (
    <Layout>
      <SEO
        title="Accept Payments & Subscriptions using Qwilr with Payhere"
        description={
          "Create payment links to get paid online. Launch a one-page website with payments built in. Embed or integrate with 1000s of platforms using our SDKs or Zapier integration."
        }
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
        ]}
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <Sidebar posts={posts} />
          <div className="md:col-span-8">
            <h1 className="text-2xl font-display text-black font-bold leading-8 md:text-4xl sm:leading-tight">
              Accept Payments & Subscriptions using Qwilr with Payhere
            </h1>

            <div className="prose mt-6">
              <img src={headerImg} className="" />

              <p>
                Several of our customers are using Payhere in conjunction with
                Qwilr to send out proposals. It seems a very fast and efficient
                way of getting things going. If your customer likes the
                proposal, Payhere then helps get the one off or recurring fee
                paid/setup.
              </p>
              <p>
                Here are two quick and simple ways to integrate Payhere into
                Qwilr.
              </p>
              <h2>1. Link text to your plan URL.</h2>
              <img src={img1} className="" />
              <p>
                Enter your text and highlight the word you want to link to your
                plan.
              </p>
              <img src={img2} className="" />
              <p>Insert the URL link to your plan.</p>
              <img src={img3} className="" />
              <p>
                This will take your customers straight to your plan to enter
                their card details.
              </p>
              <img src={img4} className="" />
              <h2>2. Embed Payhere into your document</h2>
              <p>Add a new block and select the embed option.</p>
              <img src={img5} className="" />
              <p>
                Select the iframe embed option and then enter your plan URL. You
                can optionally place ?embedded=yes onto the end of your URL to
                use a more stripped down version of the UI.
              </p>
              <img src={img6} className="" />
              <p>
                Your plan will then appear within the document, meaning that
                customers do not have to leave the page to pay you.
              </p>
              <img src={img7} className="" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IntegrationGlideAppsPage
